
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IBen } from "@/interface/IBen";
import { FeedbackModel } from "@/enum/FeedbackModel";

interface ModuleOption {
  text: string;
  value: FeedbackModel;
  image: string;
}

@Component
export default class FeedbackDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;

  feedback: string = '';
  selectedModule: FeedbackModel | null = null;
  isSubmitting: boolean = false;
  user: IBen | null = null;
  submittedModules: FeedbackModel[] = [];

  modules: ModuleOption[] = [
    { text: 'OfisPro', value: FeedbackModel.OFISPRO, image: require("@/assets/cloudicon.svg") },
    { text: 'İcraPro', value: FeedbackModel.ICRAPRO, image: require('@/assets/icraprooldicon.svg') },
    { text: 'e-İcraPro', value: FeedbackModel.EICRAPRO, image: require('@/assets/eicraproicon.png') }
  ];

  features: string[] = [
    "Yeni e-İcraPro'nun yeniden tasarlanan arayüzü, şimdi her zamankinden daha şık ve kolay.",
    "Hatalı ya da eksik işlemlerin kontrolü için takip talebi ön izlemesini görüntüleyebilme",
    "Tevzi Al ve Aç özelliği ile daha hızlı takip açabilme",
    "Ölüm kaydı olan borçlular için takip hazırlanıp, hazırlanmamasının belirlenebilmesi",
    "Birden fazla XML ile aynı anda işlem yapabilme",
    "İşlemlere dair iş listesi oluşturarak, oluşturulan iş listesine diğer bilgisayarlardan erişim imkanı",
    "Toplu işlemler sırasında, UYAP oturumu kapanırsa ya da elektrik kesintisi olursa, oturum açıldıktan sonra kalınan yerden devam edilebilmesi",
    "İcra İşlemleri kısmından, UYAP'taki dosyanıza tüm haciz taleplerinizi talep karşılama ekranına gönderebilme"
  ];

  created() {
    try {
      const userStr = sessionStorage.getItem('user');
      if (userStr) {
        this.user = JSON.parse(userStr);
      }
      const submittedModulesStr = localStorage.getItem('submittedFeedbackModules');
      if (submittedModulesStr) {
        this.submittedModules = JSON.parse(submittedModulesStr);
      }
    } catch (error) {
      console.error('Error parsing stored data:', error);
    }
  }

  get dialog(): boolean {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit('input', value);
  }

  get isModuleSubmitted(): boolean {
    return this.selectedModule !== null && this.submittedModules.includes(this.selectedModule);
  }

  get selectedModuleName(): string {
    return this.modules.find(m => m.value === this.selectedModule)?.text || '';
  }

  @Watch('dialog')
  onShowChange(newValue: boolean) {
    if (!newValue) {
      this.feedback = '';
      this.selectedModule = null;
      this.isSubmitting = false;
    }
  }

  async submitFeedback() {
    if (!this.feedback || this.selectedModule === null || !this.user) return;

    if (this.isModuleSubmitted) {
      this.$toast.error('Bu modül için daha önce geri bildirim gönderdiniz. Her modül için yalnızca bir kez geri bildirim gönderebilirsiniz.');
      return;
    }

    this.isSubmitting = true;
    try {
      await this.$http.post('/api/v1/feedback', {
        module_name: this.selectedModuleName,
        name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        content: this.feedback
      });

      // Record that feedback has been submitted for this module
      this.submittedModules.push(this.selectedModule);
      localStorage.setItem('submittedFeedbackModules', JSON.stringify(this.submittedModules));

      this.$emit('submit', this.feedback);
      this.dialog = false;
      this.$toast.success('Görüş ve önerileriniz için teşekkür ederiz!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      this.$toast.error('Görüş ve önerileriniz gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    } finally {
      this.isSubmitting = false;
    }
  }
}
