import app from "@/main";
import {Mixins} from "../Mixins";
import Vekil from "./Vekil";
import TemelBilgileri from "./TemelBilgileri";
import HesapBilgisi from "./HesapBilgisi";
import Adres from "./Adres";
import TakipRolleri from "./TakipRolleri";
import {HashArguments} from "./HashArguments";
import TarafInterface from "./TarafInterface";
import {DosyaFormDataInterface} from "../DosyaFormDataInterface";
import {MtsDosyaFormData} from "../MtsDosyaFormData";
import {KisiSorgulaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgula";

export default class TarafKisi extends TarafInterface {
    constructor(isMts = false) {
        super();
        this.isMts = isMts;
    }

    async parse(
        tarafDomNode: Element,
        vekilList: Vekil[],
        formData: DosyaFormDataInterface | MtsDosyaFormData,
    ) {
        let kisiKurumBilgileriNode = tarafDomNode.querySelector('kisikurumbilgileri');
        let kisiTumBilgileriNode, adresDomNode;
        if (kisiKurumBilgileriNode) {
            kisiTumBilgileriNode = kisiKurumBilgileriNode.querySelector('kisitumbilgileri');
            adresDomNode = kisiKurumBilgileriNode.querySelector("adres");
        }
        this.formData = formData;
        this.kisiMi              = true;
        this.tarafTuru           = "KISI";
        //@ts-ignore
        this.temelBilgileri      = TemelBilgileri.parse(kisiTumBilgileriNode);
        this.adSoyad = this.temelBilgileri.adi + " " + this.temelBilgileri.soyadi;
        //@ts-ignore
        this.adresBilgisi        = await Adres.parse(adresDomNode,this.isMts);
        this.adresBilgisi.kaynak = "xml";
        this.adresList.push(this.adresBilgisi);
        //@ts-ignore
        let rolID = tarafDomNode.querySelector('roltur').getAttribute("rolid");
        //@ts-ignore
        let rol = tarafDomNode.querySelector('roltur').getAttribute("rol");
        let tarafSifati = new TakipRolleri().find(rolID);
        if (tarafSifati)
            this.tarafSifati = tarafSifati
        else
            throw new Error("TARAF " + this.adSoyad + " Rol Türü Geçerli Değil => " + rol)
        if (this.isMts && tarafSifati)
            this.rolGirisBilgisi = tarafSifati;
        this.hesapBilgisi       = new HesapBilgisi(tarafDomNode);
        this.refId              = Mixins.nullUndefCleaner(tarafDomNode.getAttribute("id"));
        this.setAlacakliMernisAdresiTercihi(formData.alacakliMernisAdresiKullan);
        this.setMernisAdresiTercihi(formData.mernisAdresiKullan);
        this.setVekil(tarafDomNode, vekilList);

        this.setHashList();
        return this;
    }

    setHashList() {
        let hashArguments = HashArguments.kisi();
        hashArguments.rolId     = this.tarafSifati.rolID.toString();
        hashArguments.adi       = this.temelBilgileri.adi;
        hashArguments.soyadi    = this.temelBilgileri.soyadi;
        hashArguments.tckimlikno = this.temelBilgileri.tcKimlikNo;
        if ((this.isMts && this.isAlacakli()) || !this.isMts)
            this.tarafHashList.push(hashArguments);
    }

    async runQuery(): Promise<void> {
        try {
            if (this.isAlacakli())
                await this.runQueryForAlacakli();
            else if (this.isBorclu())
                await this.runQueryForBorclu();
            else
                await this.setTarafKisi(null)
            await this.setVekilListesi();
            this.addMessage('success', 'Vekil Listesi hazırlandı!');
            return ;
        } catch (e) {
            this.addMessage('error', e);
            // @ts-ignore
            throw new Error(e.message);
        }
    }

    async runQueryForAlacakli(): Promise<any> {
        try {
            if (!Mixins.checkTcNum(this.temelBilgileri.tcKimlikNo)) {
                await this.setTarafKisi(null);
                this.addMessage('success', 'Taraf kişi xml eklendi');
            } else {
                try {
                    this.addMessage('info', 'Alacaklı kişi sorgulanıyor!')
                    let kisi = await app.$uyap.KisiSorgula().run(
                        {
                            tcKimlikNo: this.temelBilgileri.tcKimlikNo
                        }
                    );
                    await this.setTarafKisi(kisi);
                    this.addMessage('success', 'Taraf kişi uyap eklendi!');
                } catch (e) {
                    this.addMessage('error', 'Alacaklı kişi sorgulama hatası!')
                    await this.setTarafKisi(null);
                }
            }
            return Promise.resolve();
        } catch (e) {
            this.addMessage('error', e);
            return Promise.reject(e);
        }
    }

    async runQueryForBorclu(): Promise<void> {
        try {
            if (Mixins.checkTcNum(this.temelBilgileri.tcKimlikNo)) {
                this.addMessage('info', 'Borçlu kişi sorgulanıyor!')
                let kisi = await app.$uyap.KisiSorgula().run({tcKimlikNo: this.temelBilgileri.tcKimlikNo.toString()});
                this.addMessage('success', 'Borçlu kişi sorgulandı!')
                this.addMessage('info', 'Borçlu ölüm kaydı sorgulanıyor!')
                console.log("this.formData",this.formData);
                if (this.borcluOlumKaydiVarMi(kisi)){
                    //@ts-ignore
                    if (this.formData.borcluOlumKaydiOlanlarinTcSilinsin == true) {
                        console.log("borcluOlumKaydiOlanlarinTcSilinsin NULL");
                        this.temelBilgileri.tcKimlikNo = "";
                        await this.setTarafKisi(null);
                    }else if (
                        //@ts-ignore
                        this.formData.borcluOlumKaydiOlanlarTakiptenCikarilsin == true){
                        throw new Error('Borçlu ' + this.temelBilgileri.adi + " " + this.temelBilgileri.soyadi + " ölüm kaydı bulunduğu için takip hazırlanmadı.");
                    }else{
                        throw new Error('Borçlu ' + this.temelBilgileri.adi + " " + this.temelBilgileri.soyadi + " ölüm kaydı bulunduğu için takip hazırlanmadı.");
                    }
                }else{
                    await this.setTarafKisi(kisi);
                }
            } else {
                this.addMessage('error', 'Borçlu TCKN yanlış ölüm kaydı sorgulaması yapılamadı!')
                await this.setTarafKisi(null);
            }
            return ;
        } catch (err) {
            this.addMessage('error', err);
            // @ts-ignore
            throw new Error(err.message);
        }
    }

    async setMernisAdres(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!Mixins.checkTcNum(this.temelBilgileri.tcKimlikNo) && this.isAlacakli()) {
                    console.log("this.temelBilgileri.tcKimlikNo",this.temelBilgileri.tcKimlikNo);
                    console.log("Mixins.checkTcNum(this.temelBilgileri.tcKimlikNo)",Mixins.checkTcNum(this.temelBilgileri.tcKimlikNo));
                    this.setMernisAdresiTercihi(false);
                    return reject({message: "tckimlikno hatalı"});
                }
                let mernisAdresi = await app.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: this.temelBilgileri.tcKimlikNo});
                this.mernisAdresiVarMi = true;
                if (mernisAdresi) {
                    /*
                    TODO: Sıkıntı çıkarsa açılacak
                    this.adresList.push({
                        'adresID': 'mernis',
                        'adres': 'Mernis',
                        'adresTuruAciklama': 'Mernis Adresi',
                        'adresTuru': 'ADRTR00013'
                    });
                    */
                    if (!this.isMts) {
                        console.log("alacakliMernisAdresiKullanilsin", this.getAlacakliMernisAdresTercihi());
                        console.log("mernisKullanilsin", this.getMernisAdresTercihi());
                        if ((this.getAlacakliMernisAdresTercihi() == true && this.isAlacakli()) ||
                            (this.getMernisAdresTercihi() == true && this.isBorclu())) {
                            this.setMernisAdresiTercihi(true);
                            this.adresListPickedIndex = this.adresList.length - 1;
                        }
                    }
                } else {
                    this.setMtsMernisAdresiFalse();
                }
                return resolve();
            } catch (e) {
                console.log("setMernisAdres Err", e);
                this.setMtsMernisAdresiFalse();
                return reject(e);
            }
        })
    }

    setMtsMernisAdresiFalse() {
        if (this.isMts)
            this.setMernisAdresiTercihi(false);
    }

    async setTarafKisi(kisi: any | TemelBilgileri): Promise<void> {
        console.log("settarafKisi init");
        return new Promise(async (resolve, reject) => {
            try {
                if (this.isMts) {
                    this.temelBilgileri.sorguTuru = 2;
                    if (kisi != null) {
                        this.temelBilgileri = kisi;
                        this.temelBilgileri.sorguTuru = 0;
                    }
                } else {
                    this.sorguTuru = 2;
                    if (kisi != null) {
                        this.temelBilgileri = kisi;
                        this.sorguTuru = 0;
                    }
                }
                if (typeof this.tarafSifati.rolID == "undefined") {
                    let errMsg = "Taraf > " + this.tarafSifati.rolAdi + " " + this.adSoyad + " UYAP'ta geçerli bir taraf olmadığı için taraflara eklenemedi.Lütfen XML dosyanızı düzenleyiniz. ";
                    this.addMessage("error", errMsg);
                    return reject(errMsg);
                }
                //Borçlu ise iban eklenmiyor ...
                console.log("if runQueryForHesapBilgisi  isAlacakli", this.isAlacakli());
                if (this.isAlacakli()) {
                    await this.runQueryForHesapBilgisi();
                    this.addMessage('success', 'IBAN listesi alındı!');
                }
                console.log("setMernisAdres before",this.temelBilgileri);
                if(
                    ( this.formData.mernisAdresiKullan && this.isBorclu() && this.temelBilgileri.tcKimlikNo ) ||
                    ( this.formData.alacakliMernisAdresiKullan && this.isAlacakli() )
                ){
                    await this.setMernisAdres();
                }else{
                    this.setMernisAdresiTercihi(false);
                }
                return resolve()
            } catch (err) {
                console.log("settarafKisi error", err);
                this.addMessage('error', err);
                return reject(err);
            }
        })
    }

    borcluOlumKaydiVarMi(kisi: KisiSorgulaCevap): boolean {
        if (
            // @ts-ignore
            //this.formData.borcluOlumKaydiOlanlarTakiptenCikarilsin === true &&
            typeof kisi.olumKaydi != "undefined" &&
            kisi.olumKaydi == true
        ) {
            return true;
        }
        return false;
    }

}

