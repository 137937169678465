
import { Vue, Component } from "vue-property-decorator";
import { ProgramPaths } from "@/enum/ProgramPaths";

@Component({
  name: 'UrunlerInfo',
  name: 'ZirveCloudButton',
})
export default class UrunlerInfo extends Vue {
  urunler = [
    {
      id: 1,
      isim: "OfisPro",
      logo: require("../../assets/cloudicon.svg"),
      color: "purple--text text--darken-2",
      color2: "purple darken-2",
      btnColor: "white",
      route: ProgramPaths.ofispro,
      secili: false,
      authorizationKey: "ofis-pro",
    },
    {
      id: 2,
      isim: "İcraPro",
      logo: require("../../assets/icraprooldicon.svg"),
      color: "blue--text text--darken-2",
      color2: "blue darken-2",
      btnColor: "white",
      route: ProgramPaths.icrapro,
      secili: false,
      authorizationKey: "icra-pro",
    },
    {
      id: 3,
      isim: "e-İcraPro",
      logo: require("../../assets/eicraproicon.png"),
      color: "amber--text text--darken-4",
      color2: "amber darken-4",
      btnColor: "white",
      route: ProgramPaths.eicrapro,
      secili: false,
      authorizationKey: "eicra-pro",
    },
    {
      id: 4,
      isim: "DavaProWeb",
      logo: require("../../assets/davaprowebicon.svg"),
      color: "deep-orange--text text--darken-4",
      color2: "deep-orange darken-4",
      btnColor: "white",
      route: ProgramPaths.davapro,
      secili: false,
      authorizationKey: "dava-pro",
    },
    {
      id: 5,
      isim: "ArabulucuPro",
      logo: require("../../assets/arabulucuproicon.svg"),
      color: "light-blue--text text--darken-4",
      color2: "light-blue darken-4",
      btnColor: "white",
      route: ProgramPaths.arabulucupro,
      secili: false,
      authorizationKey: "arabulucu-pro",
    },
  ];

  get aktifUrun() {
    return this.urunler.find(urun => urun.secili) || this.urunler[0];
  }

  secimiDegis(urun: any) {
    this.urunler.forEach((x: any) => {
      x.secili = false;
    });
    urun.secili = true;
  }

  getRoute(id: number) {
    return this.urunler[id - 1].route;
  }

  mounted() {
    // Store'dan aktif ürünü kontrol et
    if (this.$store.getters.isOfisPro)
      this.urunler[0].secili = true;
    if (this.$store.getters.isIcraPro)
      this.urunler[1].secili = true;
    if (this.$store.getters.isEicraPro)
      this.urunler[2].secili = true;
    if (this.$store.getters.isDavaPro)
      this.urunler[3].secili = true;
    if (this.$store.getters.isArabulucuPro)
      this.urunler[4].secili = true;
  }
}
