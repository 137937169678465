
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ChatbotDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;



  get dialog(): boolean {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit('input', value);
  }
}
