import _Vue from "vue";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {AdresTuru} from "@/enum/AdresTuru";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiAlani, AlacakKalemiAlaniLabel} from "@/enum/AlacakKalemiAlani";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {store} from "@/store";
import axios from "axios";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import app from "@/main";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import Crypto from "crypto";
//@ts-ignore
import {EvrakB64FileObject} from "@/plugins/uyap-plugin/evrak_gonder/EvrakGonder";
import {PathToProduct, Product} from "@/enum/Product";
import {LisansEntity} from "@/entity/LisansEntity";
import Swal from "sweetalert2";

export class Helper {
    isAuthorized(key: string): boolean {
        let user = store.state.user;
        if (user?.roles) {
            if (user.roles[0].is_admin == 1) return true;
            let userPermissions = user.roles[0]?.permissions;
            if (userPermissions) {
                return userPermissions.find((permission: any) => permission.name === key);
            }
        }
        return false;
    }

    licenceIsValid(urun: Product|PathToProduct): boolean {
        let productLicence: LisansEntity = store.getters.licenses.find((licence: LisansEntity) => licence.product_id == urun);
        if (productLicence) {
            if ((new Date(productLicence.start_date).getTime() <= new Date().getTime()) &&
                (new Date(productLicence.end_date).getTime() >= new Date().getTime()))
                return true;
            else
                return false;
        } else
            return false;
    }

    tarihFarkiGunSayisi(begin: Date, end: Date) {
        var date1 = new Date(begin.toISOString().substr(0, 10));
        var date2 = new Date(end.toISOString().substr(0, 10));
        let num = (date2.getTime() - date1.getTime()) / 86400000;
        return num;
    }

    tarihSaatStr(val: Date | null, convertToUtc3: boolean = false): string {
        if (val === null) {
            return "";
        }
        try {
            let date:any = val;
            if (convertToUtc3){
                let convertedDate= new Date(date).setHours(new Date(date).getHours() + 3);
                date = new Date(convertedDate).toISOString();
            }
            return this.tarihStr(date) + " " + this.saatStr(date);
        } catch (e) {
            return "";
        }
    }

    hash(obj:any){
        return Crypto.createHash('sha1').update(JSON.stringify(obj)).digest().toString('hex');
    }

    getNestedValue(obj: any, key: string) {
        return key.split('.').reduce((o, i) => o[i], obj);
    }

    async clickCopyText(str: string) {
        try {
            await navigator.clipboard.writeText(str);
            app.$toast("Metin kopyalandı");
        } catch (e) {
            app.$toast.error("Metin kopyalanamadı");
        }
    }

    tarihStr(val: Date | string): string {
        try {
            if (typeof val === "string") { //
                return val.substr(0, 10).split('-').reverse().join('/');
            } else if (typeof val === "object") {
                return val.toISOString().substr(0, 10).split('-').reverse().join('/');
            }
        } catch (e) {
        }
        return "";
    }

    saatStr(val: Date | string): string {
        try {
            if (typeof val === "string") {
                return val.substr(11, 8);
            } else if (typeof val === "object") {
                return val.toISOString().substr(11, 5);
            }
        } catch (e) {
        }
        return "";
    }

    kisiKurumAd(val: any): string {
        if (val == null) {
            return "";
        }
        if (val.kisi != null) {
            return val.kisi.ad + ' ' + val.kisi.soyad;
        } else if (val.kurum != null) {
            return val.kurum.sirket_adi;
        } else {
            return ""
        }

    }

    kisiKurumVarsayilanAdres(val: any): AdresEntity | null {
        if (val == null) {
            return null;
        }
        if (val.kisi != null) {
            let kisi: KisiEntity = val.kisi;
            return kisi.varsayilan_adres;
        } else if (val.kurum != null) {
            let kurum: KurumEntity = val.kurum;
            return kurum.varsayilan_adres;
        }
        return null;
    }


    hesapNoOrIbanStr(row: BankaHesabiEntity): string {
        if (row.iban) {
            return row.iban === null ? "" : row.iban;
        } else {
            return row.sube_adi + " - " + row.hesap_no;
        }
    }

    tcknOrVergiNo(val: any): string {
        if (val == null) {
            return "";
        }
        if (val.kisi != null) {
            return val.kisi.tc_kimlik_no
        }
        if (val.kurum != null) {
            if (val.kurum.vergi_no) {
                return val.kurum.vergi_no.toString();
            }
            return "";
        }
        return ""
    }

    takipTipiStr(takipTipi: TakipTipiEntity): string {
        return takipTipi ? takipTipi.no + " (" + takipTipi.eski_no + ") - " + takipTipi.aciklama : "";
    }

    tutarStr(val: number, paraBirimi: ParaBirimiEntity | null = null): string {
        // let temp = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'}).format(val);
        if (!val) {
            val = 0.00;
        }
        return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2}).format(val) + " " + (paraBirimi ? paraBirimi?.kisa_isim : " TL");

    }

    tutarStrIstatistik(val: number, paraBirimi: ParaBirimiEntity | null = null): string {
        // let temp = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'}).format(val);
        if (!val) {
            val = 0.00;
        }
        return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2}).format(val) + " " + (paraBirimi ? paraBirimi?.kisa_isim : " ₺");

    }

    newTutarStr(val: number): string {
        if (!val) {
            val = 0.00;
        }
        return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2}).format(val);
    }

    async takipAcOnay():Promise<void>{
        const result = await Swal.fire({
            title: 'Takip Açma Onay',
            text: 'Onaylayın ',
            icon: "warning",
            allowOutsideClick: false,
            html:
                '<div class="row">' +
                '<div class="col-12"><label class="swal2-checkbox"><input id="onay" type="checkbox" class="swal2-checkbox" required><span class="swal2-label font-weight-bold">Açmak istediğim takipleri kontrol ettiğimi onaylıyorum.</span></label></div>' +
                '</div>',
            confirmButtonText: 'Tamam',
            preConfirm: function () {
                return new Promise( (resolve)=> {
                    const checkbox = document.getElementById('onay') as HTMLInputElement;
                    resolve({
                        onay: checkbox?.checked
                    })
                })
            }
        });
        console.log("result",result);
        if(result.value.onay === false)
            throw new Error("Lütfen açılacak olan takiplerinizi kontrol ettiğinizi onaylayın.")
    }

    oranStr(val: number) {
        if (!val) {
            val = 0
        }
        return val + "%";
    }

    setCookie(name:string,value:any,days:number) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    getCookie(name:string) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    eraseCookie(name:string) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    changeUyapDateFormat(date: string): string {
        let tarih = new Date(date);
        let tarihObj = {
            gun: tarih.getDate(),
            ay: (tarih.getMonth() + 1),
            yil: tarih.getFullYear()
        }
        return `${tarihObj.gun}.${tarihObj.ay}.${tarihObj.yil}`;
    }

    tarihGecenSure(val: Date | null): string {
        let thisTime = new Date();                              // Bugün
        if (val === null)
            return "bos deger";
        let diff = thisTime.getTime() - val.getTime();
        let retvaldk = diff / (1000 * 60);                      // dk bazında fark
        let retvalsa = diff / (1000 * 60 * 60);                 // sa bazında fark
        let retvalgun = diff / (1000 * 60 * 60 * 24);           // gün bazında fark
        let retvalhafta = diff / (1000 * 60 * 60 * 24 * 7);     // hafta bazında fark
        let retvalay = diff / (1000 * 60 * 60 * 24 * 30);       // ay bazında fark
        let retvalyil = diff / (1000 * 60 * 60 * 24 * 365);     // yıl bazında fark
        if (retvaldk < 60)
            return Math.round(retvaldk).toString() + " dk";
        else if (retvalsa < 24)
            return Math.round(retvalsa).toString() + " saat";
        else if (retvalgun < 7)
            return Math.round(retvalgun).toString() + " gün";
        else if (retvalhafta < 5)
            return Math.round(retvalhafta).toString() + " hafta";
        else if (retvalay < 12)
            return Math.round(retvalay).toString() + " ay";
        else
            return Math.round(retvalyil).toString() + " yıl";
    }

    tarihFarkiDetayli(baslangicTarihi: Date, bitisTarihi: Date = new Date()): object {
        let subDate = bitisTarihi.getTime() - baslangicTarihi.getTime();
        let tarihAraligi = {
            gun: 0,
            ay: 0,
            yil: 0
        }
        tarihAraligi.yil = Math.floor(subDate / (1000 * 60 * 60 * 24 * 365));
        if (tarihAraligi.yil > 0) subDate -= (tarihAraligi.yil * 1000 * 60 * 60 * 24 * 365);
        tarihAraligi.ay = Math.floor(subDate / (1000 * 60 * 60 * 24 * 30));
        if (tarihAraligi.ay > 0) subDate -= (tarihAraligi.ay * 1000 * 60 * 60 * 24 * 30);
        tarihAraligi.gun = Math.ceil(subDate / (1000 * 60 * 60 * 24));
        return tarihAraligi;
    }

    tcKimlikNoValidate(tcKimlikNo: string): boolean {
        let value10 = Number(tcKimlikNo.toString().substring(10, 11));
        let value9 = Number(tcKimlikNo.toString().substring(9, 10));
        let odd = Number(tcKimlikNo.toString().substring(0, 1)) + Number(tcKimlikNo.toString().substring(2, 3)) +
            Number(tcKimlikNo.toString().substring(4, 5)) + Number(tcKimlikNo.toString().substring(6, 7)) +
            Number(tcKimlikNo.toString().substring(8, 9));
        let even = Number(tcKimlikNo.toString().substring(1, 2)) + Number(tcKimlikNo.toString().substring(3, 4)) +
            Number(tcKimlikNo.toString().substring(5, 6)) + Number(tcKimlikNo.toString().substring(7, 8));
        let digit10 = (odd * 7 - even) % 10;
        if (digit10 < 0) digit10 += 10;
        let total = (odd + even + value9) % 10;

        return !(digit10 != value9 || total != value10)
    }

    yabanciKimlikNoValidate(yabanciKimlikNo: string): boolean {
        let firstTwoValue = Number(yabanciKimlikNo.toString().substring(0, 2));
        return firstTwoValue == 99;
    }

    borcluIl(borclu: BorcluEntity): string {
        if (borclu.kisi_id != null) {
            if (borclu.kisi.varsayilan_adres != null) {
                if (borclu.kisi.varsayilan_adres.adres_turu.id == AdresTuru.mernis)
                    return 'Mernis Adresi';
                else if (borclu.kisi.varsayilan_adres.adres_turu.id == AdresTuru.mersis)
                    return 'Mersis Adresi';
                else if (borclu.kisi.varsayilan_adres.sehir != null)
                    return borclu.kisi.varsayilan_adres.sehir.isim;
                else
                    return 'Bilgi Yok';
            } else
                return 'Bilgi Yok';
        } else {
            if (borclu.kurum.varsayilan_adres != null) {
                if (borclu.kurum.varsayilan_adres.adres_turu.id == AdresTuru.mernis)
                    return 'Mernis Adresi';
                else if (borclu.kurum.varsayilan_adres.adres_turu.id == AdresTuru.mersis)
                    return 'Mersis Adresi';
                else if (borclu.kurum.varsayilan_adres.sehir != null)
                    return borclu.kurum.varsayilan_adres.sehir.isim;
                else
                    return 'Bilgi Yok';
            } else
                return 'Bilgi Yok';
        }
    }

    borcluIlce(borclu: BorcluEntity): string {
        if (borclu.kisi_id != null) {
            if (borclu.kisi.varsayilan_adres != null) {
                if (borclu.kisi.varsayilan_adres.adres_turu.id == AdresTuru.mernis)
                    return 'Mernis Adresi';
                else if (borclu.kisi.varsayilan_adres.adres_turu.id == AdresTuru.mersis)
                    return 'Mersis Adresi';
                else if (borclu.kisi.varsayilan_adres.ilce != null)
                    return borclu.kisi.varsayilan_adres.ilce.isim;
                else
                    return 'Bilgi Yok'
            } else
                return 'Bilgi Yok'
        } else {
            if (borclu.kurum.varsayilan_adres != null) {
                if (borclu.kurum.varsayilan_adres.adres_turu.id == AdresTuru.mernis)
                    return 'Mernis Adresi';
                else if (borclu.kurum.varsayilan_adres.adres_turu.id == AdresTuru.mersis)
                    return 'Mersis Adresi';
                else if (borclu.kurum.varsayilan_adres.ilce != null)
                    return borclu.kurum.varsayilan_adres.ilce.isim;
                else
                    return 'Bilgi Yok';
            } else
                return 'Bilgi Yok';
        }
    }

    arrRemoveItem(arr: Array<any>, item: any) {
        var index = arr.indexOf(item);
        if (index != -1) {
            arr.splice(index, 1);
        }
    }

    getOldestVadeTarihiFromAlacak(alacak: AlacakEntity): Date {
        let oldestVadeTarihi!: Date;
        alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacak_kalemi_alani_id === AlacakKalemiAlani.asilAlacak) {
                let alacakKalemiVadeTarihi = new Date(alacakKalemi.vade_tarihi);
                if (!!oldestVadeTarihi || oldestVadeTarihi > alacakKalemiVadeTarihi) {
                    oldestVadeTarihi = alacakKalemiVadeTarihi;
                }
            }
        });
        return oldestVadeTarihi;
    }

    calculateMonthDiffBetweenDates(date1: Date, date2: Date) {
        let dateDiff = new Date(date2.getTime() - date1.getTime());
        let yearDiff = dateDiff.getUTCFullYear() - 1970;
        let monthDiff = dateDiff.getUTCMonth()
        return (yearDiff * 12) + monthDiff;
    }

    printComponent(component_id: string, delete_button: boolean = false, delete_islemler: boolean = false) {
        // DataTable'ı kopyala
        let prtHtml:any = document.getElementById(component_id)?.cloneNode(true);
        // Bütün butonları sil
        if (delete_button) {
            let element = prtHtml?.getElementsByTagName("button"), index;
            for (index = element.length - 1; index >= 0; index--) {
                if (element[index]) {
                    if (element[index].parentElement.tagName == "TD")
                        element[index].parentElement.remove(element[index]);
                    else if (element[index].parentElement.tagName == "DIV")
                        element[index].parentElement.removeChild(element[index]);
                }
            }
        }
        // İşlemler kısmını sil
        if (delete_islemler) {
            let element = prtHtml?.querySelector("[aria-label='İşlemler']");
            element.parentElement.removeChild(element);
        }
        // InnerHTML'e dönüştür
        prtHtml = prtHtml?.innerHTML;
        // Bütün stylesheets al (CSS)
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        // Çıktı ekranı
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint?.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);
        WinPrint?.document.close();
        WinPrint?.focus();
        if (WinPrint){
            WinPrint.onfocus = function () {
                setTimeout(function () {
                    WinPrint?.print();
                    WinPrint?.close();
                }, 500);
            };
        }

    }

    seoUrl(text :string):string{
        text = text.replace(/\ /g,'-')
            .replace(/\(/g,'-')
            .replace(/\)/g,'-')
            .replace(/\//g,'-')
            .replace(/\\/g,'-');
        return this.trToEn(text);
    }
    trToEn(text:string):string{
        return text.toLowerCase()
            .replace(/Ç/g, 'c')
            .replace(/ç/g, 'c')
            .replace(/ö/g, 'o')
            .replace(/Ö/g, 'o')
            .replace(/Ü/g, 'u')
            .replace(/ü/g, 'u')
            .replace(/Ğ/g, 'g')
            .replace(/ğ/g, 'g')
            .replace(/Ş/g, 's')
            .replace(/ş/g, 's')
            .replace(/İ/g, 'i')
            .replace(/ı/g, 'i')
    }
    tutarToText(sayi: any) {
        if (typeof sayi == "number")
            sayi = sayi.toFixed(2).toString();
        sayi = sayi.replace(".", ",");
        let rakam = sayi.replace(/\B(?=(\d{3})+(?!\d))/g, ".").split(",");
        let tamsayi = rakam[0];
        let ondalik = rakam[1] || "";

        const birler = ["", "Bir", "İki", "Üç", "Dört", "Beş", "Altı", "Yedi", "Sekiz", "Dokuz"];
        const onlar = ["", "On", "Yirmi", "Otuz", "Kırk", "Elli", "Altmış", "Yetmiş", "Seksen", "Doksan"];
        const binler = ["", "Bin", "Milyon", "Milyar", "Trilyon", "Katrilyon", "Kentilyon"];
        let sonuc:any = [];
        let adim = 0;

        for (let i = tamsayi.split(".").length; i > 0; i--) {
            sayi = tamsayi.split(".")[i - 1];
            if (sayi.length == 1)
                sayi = "00" + sayi;
            if (sayi.length == 2)
                sayi = "0" + sayi;
            let c = "";
            for (let j = 1; j < sayi.length + 1; j++) {
                if (j == 1 && sayi[j - 1] == 1)
                    c += "Yüz";
                else if (j == 1 && birler[sayi[j - 1]] != "")
                    c += birler[sayi[j - 1]] + "Yüz";
                else if (j == 2)
                    c += onlar[sayi[j - 1]];
                else if (j == 3 && tamsayi.length == 5 && sayi[j - 1] == 1 && adim == 1)
                    c += " ";
                else if (j == 3)
                    c += birler[sayi[j - 1]];
            }
            if (c != "")
                sonuc.push(c + binler[adim]);
            adim++;
        }
        if (sonuc.length != 0)
            sonuc = sonuc.reverse().join("");
        else
            sonuc = "";
        if (ondalik.length == 1)
            ondalik = ondalik + "0";
        if (ondalik != "") {
            if (ondalik != "00") sonuc += "Virgül"
            sonuc += onlar[ondalik[0]] + birler[ondalik[1]];
        }
        sonuc = sonuc.replace(/ /g, " ").trim();
        return sonuc;
    }

    async download(name: string, URL: string) {
        return new Promise((resolve, reject) => {
            app.$http({
                url: URL,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                let fileURL = window.URL.createObjectURL(new Blob([!!response.data ? response.data : response]));
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(response);
            }).catch((error) => {
                new StatusCodeHelper().error(error);
                reject(error.message)
            });
        })
    }

    downloadPost(name: string, URL: string, data: any) {
        app.$http({
            url: URL+"?" + new URLSearchParams(data).toString(),
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([!!response.data ? response.data : response]));
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch((error) => {
            new StatusCodeHelper().error(error);
        });
    }

    async getBlobFromLink(URL: string):Promise<Blob> {
        return new Promise((resolve, reject) => {
            app.$http({
                url: URL,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                console.log("response Blob",response);
                return resolve(!!response.data ? response.data : response);
            }).catch((error) => {
                new StatusCodeHelper().error(error);
                return reject(error);
            });
        })
    }

    uyapTebligatAdresSorgula(){
        // IcraTebligatTalebiAdresSorgula
    }

    turkishToUpper(str: string) {
        let letters: any = {
            "a": "A",
            "b": "B",
            "c": "C",
            "d": "D",
            "e": "E",
            "f": "F",
            "g": "G",
            "ğ": "Ğ",
            "h": "H",
            "ı": "I",
            "i": "İ",
            "j": "J",
            "k": "K",
            "l": "L",
            "m": "M",
            "n": "N",
            "o": "O",
            "ö": "Ö",
            "p": "P",
            "r": "R",
            "s": "S",
            "ş": "Ş",
            "t": "T",
            "u": "U",
            "ü": "Ü",
            "v": "V",
            "y": "Y",
            "z": "Z",
            "A": "A",
            "B": "B",
            "C": "C",
            "D": "D",
            "E": "E",
            "F": "F",
            "G": "G",
            "Ğ": "Ğ",
            "H": "H",
            "I": "I",
            "İ": "İ",
            "J": "J",
            "K": "K",
            "L": "L",
            "M": "M",
            "N": "N",
            "O": "O",
            "Ö": "Ö",
            "P": "P",
            "R": "R",
            "S": "S",
            "Ş": "Ş",
            "T": "T",
            "U": "U",
            "Ü": "Ü",
            "V": "V",
            "Y": "Y",
            "Z": "Z"
        }
        let out = "";
        for (var i = 0; i < str.length; i++) {
            if (typeof letters[str[i]] != "undefined")
                out += letters[str[i]];
            else
                out += str[i];
        }
        return out;
    }

    turkishToLower(str: string) {
        let letters: any = {"İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç"};
        str = str.replace(/(([İIŞĞÜÇÖ]))+/g, (letter: any) => {
            return letters[letter];
        })
        return str.toLowerCase();
    }

    onlyDigit(text: string) {
        return text.replace(/[^0-9]/g, "");
    }

    getDateNow(seperator: string, dateDiff: any, donemYil = false) {
        let today:Date|string = new Date();
        let dd:string|number = today.getDate();
        let mm:string|number = today.getMonth(); //January is 0!
        let yyyy = today.getFullYear();
        if (dateDiff) {
            if (dateDiff.indexOf("month") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setMonth(mm - dateDiff.split('-')[1].trim());
                } else {
                    today.setMonth(mm + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
            if (dateDiff.indexOf("day") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setDate(dd - dateDiff.split('-')[1].trim());
                } else {
                    today.setDate(dd + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
            if (dateDiff.indexOf("year") >= 0) {
                if (dateDiff.indexOf("-") >= 0) {
                    today.setFullYear(yyyy - dateDiff.split('-')[1].trim());
                } else {
                    today.setFullYear(yyyy + parseInt(dateDiff.split('+')[1].trim()));
                }
            }
        }
        dd = today.getDate();
        mm = today.getMonth() + 1;
        yyyy = today.getFullYear();

        if (!seperator)
            seperator = '/';
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = dd + seperator + mm + seperator + yyyy;
        if (donemYil == true) {
            today = yyyy + seperator + mm;
        }
        return today;
    }

    onlyNumber(event: any) {
        let charCode = (event.keyCode) ? event.keyCode : event.which;
        // 44: Virgül 45:Tire 46: Nokta 101: E
        if (charCode == 101 || charCode == 44 || charCode == 45 || charCode == 46)
            event.preventDefault();
    }

    adlar(sahislar: Array<BorcluEntity | AlacakliEntity>) {
        let adlar: Array<string> = [];
        sahislar.forEach((sahis:any) => (sahis.muvekkil ? adlar.push(this.kisiKurumAd(sahis.muvekkil)) : adlar.push(this.kisiKurumAd(sahis))));
        return adlar.join(', ');
    }

    alacakKalemiAdiGetir(alacakKalemiAlaniId: number, alacakBelgeTuruId: number | null = null) {
        switch (alacakBelgeTuruId) {
            case AlacakBelgeTuru.nafaka: {
                return "Eklenen Nafaka";
            }
            default: {
                return AlacakKalemiAlaniLabel[AlacakKalemiAlani.takipSonrasiAsilAlacak]
            }
        }
    }

    convertToBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    base64DataCleaner(data:EvrakB64FileObject):EvrakB64FileObject{
        let splitedB64 :any =  data.base64.split(";base64,");
        if(splitedB64.length > 1){
            data.base64 = splitedB64[1];
            data.type = splitedB64[0].split("data:")[1]
        }
        return data;
    }

    base64toBlob(base64Data:any, contentType:any): Blob {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = window.atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    uyapB64toBlob(b64Data:any, contentType:any = null, sliceSize:any = null) {
        try{
            contentType = contentType || '';
            sliceSize = sliceSize || 512;
            let byteCharacters = window.atob(b64Data);
            let byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            let blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }catch (ex){
           throw new Error(ex.message);
        }
    }

    yasOgren(dogumTarihi: Date | string): number {
        let today = new Date();
        let birthDate = new Date(dogumTarihi);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export default function HelperPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$helper = new Helper();
}


