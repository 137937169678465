
import {Component, Vue, Prop, Ref, Watch} from "vue-property-decorator";
import {AxiosResponse} from "axios";
import {IDefaultValuable} from "@/interface/IDefaultValuable";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {VForm} from "@/types";
import _ from 'lodash';

@Component({
  components: {},
})
export default class FormWrapper extends Vue implements IDefaultValuable {
  @Prop() action!: string;
  @Prop() hideCloseButton!: any;
  @Prop() hideSubmitButton!: string | boolean;
  @Prop() closeButtonText!: string;
  @Prop() submitButtonText!: string;
  @Prop() successOnValidate!: boolean | string;
  @Prop() disabled!: string | boolean;
  @Prop() disabledSave!: boolean;
  @Prop({
    type: Object, default: () => ({})
  }) defaultValues!: any;

  @Ref('form') readonly form!: VForm;
  defaultLocalValue: object = {};
  valid: boolean = false;
  onFly: boolean = false;

  get isCloseButtonHidden() {
    return this.hideCloseButton || this.hideCloseButton === "";
  }

  get isSubmitButtonHidden() {
    return this.hideSubmitButton || this.hideSubmitButton === "";
  }

  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  mounted() {
    if ((this.$parent as any).localValue?.id || (this.isSuccessOnValidate && (!!this.$parent as any).localValue))
      this.setDefaultLocalValue();
  }

  setDefaultLocalValue() {
    this.defaultLocalValue = _.cloneDeep((this.$parent as any).localValue);
  }

  setLocalValue() {
    if ((!!this.$parent as any).localValue) {
      Object.assign((this.$parent as any).localValue,{...this.defaultLocalValue});
      (this.$parent as any).$emit('onInput', (this.$parent as any).localValue);
    }
    else
      this.$emit('onInput', {...this.defaultLocalValue});
  }

  onClose() {
    this.resetForm();
    if ((this.$parent as any).localValue?.id || this.isSuccessOnValidate)
      this.setLocalValue();
    if (typeof (this._events.onClose) === "object") {
      this.$emit("onClose");
    } else {
      (this.$parent as any).$emit("onClose");
    }
  }

  onSuccess(data: any) {
    if (typeof this._events.onSuccess === "object") {
      this.$emit("onSuccess", data);
    }else {
      (this.$parent as any).$emit("onSuccess", data);
    }
    this.resetForm();
  }

  onValidate() {
    this.form.validate();
    let isChildrenValid = this.validateChildFormWrappers();
    if (this.valid && isChildrenValid) {
      this.setDefaultLocalValue();
      this.$emit("onValidate", this.valid);
    }
    return this.valid && isChildrenValid;
  }

  validateForm() {
    this.form.validate();
    let formChildValid = this.validateChildFormWrappers();
    return this.valid && formChildValid;
  }

  validateChildFormWrappers(component: Vue = this) {
    let valid=true
    let fn = (com: any) => {
      com.$children?.forEach((child: Vue) => {
        if (child.$options.name === "FormWrapper" || child.$options._componentTag == 'form-wrapper') {
          child.$refs.form?.validate();
          let isChildValid = child.valid !== undefined ? child.valid : true;
          valid = valid && isChildValid;
        } else if (child.$options._componentTag === "form-dialog") {
          return true;
        }
        return valid && fn(child);
      });
    }
    fn(component);
    return valid;
  }

  setDefaultValues() {
    if (this.defaultValues) {
      Object.keys(this.defaultValues).forEach((defaultValueKey: string) => {
        let found = false;
        Object.keys((this.$parent as any).localValue).forEach((key: string) => {
          if (defaultValueKey === key) {
            found = true;
            if ((!this.$parent as any).localValue[key]) {
              (this.$parent as any).localValue[key] = this.defaultValues[key];
            }
          }
        });
        if (!found) {
          (this.$parent as any).localValue[defaultValueKey] = this.defaultValues[defaultValueKey];
        }
      });
    }
  }

  resetForm() {
    this.form.resetValidation()
  }

  getRequestPromise() {
    let requestPromise;
    this.setDefaultValues();
    if ((this.$parent as any).localValue.id) {
      requestPromise = this.$http.put(`${this.action}/${(this.$parent as any).localValue.id}`, (this.$parent as any).localValue);
    } else {
      requestPromise = this.$http.post(this.action, (this.$parent as any).localValue)
    }
    return requestPromise;
  }

  onSubmit() {
    let valid=this.onValidate();
    if (valid) {
      if (this.isSuccessOnValidate) {
        this.onSuccess((this.$parent as any).localValue);
      } else if (this.action) {
        this.onFly = true;
        let request = this.getRequestPromise();
        request.then((response: AxiosResponse) => {
          this.onSuccess(response.data);
        }).finally(() => {
          this.onFly = false;
        });
      }
    }
  }
}
